import React, { useEffect, useState } from 'react';
import { getSalesTarget, getFinancialYear } from '../../services/api/salesTargetService';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';
import Chart from "react-apexcharts";

function EmployeeMonthlyTarget() {

    const [isPageLoader, setPageLoader] = useState(false);
    const [FinancialYearList, setFinancialYearList] = useState([]);   
    const [TargetType, setTargetType] = useState('monthly');
    const [empList, setEmpList] = useState([]);
    const [loading, isLoading] = useState(false);
    const [ChartData, setChartData] = useState([]);
    const { financialYear } = useParams();
    const [FinancialYearId, setFinancialYearId] = useState(financialYear);
    const {empId} = useParams();

    const getFinacialYearList = async () => {
        try {
            const response = await getFinancialYear(empId);
            setFinancialYearList(response.data);
        } catch (err) {

        }
    };

    useEffect(() => {
        (async () => {
            if (empId != null)
             setPageLoader(true);
            await getFinacialYearList();           
            await getEmployeesTarget();
            setPageLoader(false);
        })();
    }, []);

    const getEmployeesTarget = async () => {
        console.log(financialYear);
        var obj = { financialYearId: FinancialYearId == null || FinancialYearId == 0 ? FinancialYearList[0].Id : FinancialYearId, grouping: TargetType, empIds: empId }
        if (financialYear != 0)
            try {
                console.log(financialYear);
                const response = await getSalesTarget(obj);
                console.log(response);
                setEmpList(response.data.list);
                console.log(empList);
                debugger;
                if (response.data.list && response.data.list.length > 0) {

                    const categories = response.data.list.map(target => target.Label);
                    const sparesSalesAcheived = response.data.list.map(target => target.SparesAmount.toFixed(3));
                    const targetData = response.data.list.map(target => target.SparesTarget.toFixed(3));
                    const serviceSalesAcheived = response.data.list.map(target => target.ServicesAmount.toFixed(3));
                    const servicetargetData = response.data.list.map(target => target.ServicesTarget.toFixed(3));
                    const amcSalesAcheived = response.data.list.map(target => target.AmcAmount.toFixed(3));
                    const amcTargetData = response.data.list.map(target => target.AmcTarget.toFixed(3));
                    const totalOutstandingAmt = response.data.list.map(target => target.outstandingDetails.OutstandingAmt);
                    const totalTarget = response.data.list.map(target => target.TotalTarget?.toFixed(2));
                    const totalTargetAcheived = response.data.list.map(target => target.TotalTargetAcheived?.toFixed(2));
                    console.log(totalOutstandingAmt);

                    const chartData = {
                        series: [
                            {
                                name: 'Spares Achieved',
                                type: 'column',
                                data: sparesSalesAcheived
                            },                            
                            {
                                name: 'Service Achieved',
                                type:'column',
                                data: serviceSalesAcheived
                            },
                            {
                                name: 'Amc Achieved',
                                type: 'column',
                                data: amcSalesAcheived
                            },
                            {
                                name: 'Total Outstanding',
                                type: 'column',
                                fillColor: 'red',
                                data: totalOutstandingAmt
                            },

                            {
                                name: 'Total Target',
                                type: 'line',
                                data: totalTarget
                            },
                            {
                                name: 'Total Target Acheived',
                                type: 'line',
                                data: totalTargetAcheived
                            },                                                   
                        ],
                        options: {
                            chart: {
                                id: 'sales-comparison-chart',
                                toolbar: {
                                    show: false
                                }
                            },
                            xaxis: {
                                categories: categories,
                            },
                            yaxis: {
                                title: {
                                    text: 'Sales'
                                }
                            },
                            markers: {
                                size: 5
                            },
                            legend: {
                                position: 'top'
                            }
                        }
                    };

                    setChartData(chartData);
                }
            } catch (err) {

            }
    }
    const [selectedRow, setSelectedRow] = useState(null);

    const handleViewClick = (index) => {
        setSelectedRow(selectedRow === index ? null : index);
    };

    const InnerTable = ({ data }) => (
        <table border="1">
            <thead>
                <tr>
                    <th># ID</th>
                    <th>InvoiceNo</th>
                    <th>EmpNo</th>
                    <th>Sales Person</th>
                    <th>Customer</th>
                    <th>Amount</th>
                </tr>
            </thead>
            <tbody>
                {data.map((item, index) => (
                    <tr key={index}>
                        <td>{item.SalesId}</td>
                        <td>{item.InvoiceNo}</td>
                        <td>{item.EmpNo}</td>
                        <td>{item.SalesPerson}</td>
                        <td>{item.CustomerName}</td>
                        <td>{item.Amount}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );

    return (
        <>
            {/* My Booking Section */}
            <div className="card aon-card">
                <div className="card-header aon-card-header">
                    <h4>Sales Target</h4>
                </div>
                <div className="card-body aon-card-body">

                    <div className="sf-availability-times-tab m-b20">
                        <div className="sf-custom-tabs sf-custom-new">
                            <div className="sf-tabs-content">
                                {/* Filter Section */}
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label>Financial Year</label>
                                            <div>
                                                <select className="form-control" name="cityId" value={FinancialYearId}
                                                    onChange={(e) => setFinancialYearId(e.target.value)}>
                                                    <option value="">Select a Financial Year</option>
                                                    {FinancialYearList && FinancialYearList.length > 0 && FinancialYearList?.map((item, key) => (
                                                        <option key={key} value={item.Id}>{item.Name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label>Select  Type</label>
                                            <div className="aon-inputicon-box">

                                                <div className="radio-inline-box sf-radio-check-row">

                                                    <div className="checkbox sf-radio-checkbox sf-radio-check-2 sf-raChe-6">
                                                        <input  value="monthly" checked={TargetType === 'monthly'} type="radio"
                                                            onChange={(e) => setTargetType('monthly')} />
                                                        <label onClick={() => setTargetType('monthly')}>Monthly</label>
                                                    </div>
                                                    <div className="checkbox sf-radio-checkbox sf-radio-check-2 sf-raChe-6">
                                                        <input value="week" type="radio" checked={TargetType === 'week'}
                                                            onChange={() => setTargetType('week')} />
                                                        <label onClick={() => setTargetType('week')}>Weekly</label>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-2 jus">
                                        <button type="button"
                                            className="admin-button m-t-38 assignButton btn margin-r-10"
                                            onClick={() => getEmployeesTarget()}>Get  Target</button>
                                    </div>
                                </div>
  
                                {/* Loader */}
                                {
                                    loading && (
                                        <div className="skeleton p-0">
                                            {[...Array(5)].map(() => (
                                                <div key={uuidv4()} className="line mb-1" style={{ height: '67px', width: '100%' }}></div>
                                            ))}
                                        </div>
                                    )
                                }
                                {
                                    empList && empList.length > 0 && (
                                        <div class="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>Employee Name</label>
                                                    <input type="text" className="form-control sf-form-control" disabled
                                                        value={empList[0].EmpNo} name='empName'
                                                    />
                                                </div>
                                            </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Employee Name</label>
                                                <input type="text" className="form-control sf-form-control" disabled
                                                    value={empList[0].EmpName} name='empName'
                                                />                                            
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Department Name</label>
                                                <input type="text" className="form-control sf-form-control" disabled
                                                    value={empList[0].Department} name='dept'
                                                />
                                            </div>
                                        </div>
                                    </div>)
                                }

                                {empList && empList.length > 0 &&
                                    <>
                                        <div className="p-3">
                                            <Chart
                                                options={ChartData.options}
                                                series={ChartData.series}
                                                type="line"
                                                height={350}
                                            />
                                        </div>

                                    </>
                                }

                                {/* MyBooking table section */}
                                {
                                    !loading && (
                                        <div className="sf-bs-data-table">
                                        <div className="table-responsive">
                                            <table className="table table-striped table-bordered example-dt-table aon-booking-table" style={{ width: '100%', tableLayout: 'fixed' }}>
                                                <thead>
                                                    <tr>
                                                        {/*<th>Employee No</th>*/}
                                                        {/*<th>Employee Name</th>*/}
                                                        {/*<th>Department</th>*/}
                                                        <th>Year</th>
                                                            <th>Label</th>
                                                            <th>Total Target</th>
                                                            <th>Total Target Acheived</th>
                                                            <th>Difference</th>
                                                       {/* <th>Total Target</th>     */}                                                   
                                                        <th>Spares Target</th>
                                                        <th>Spares Target Achieved</th>
                                                        <th>Services Target</th>
                                                        <th>Services Target Achieved</th>
                                                        <th>Amc Target</th>
                                                        <th>Amc Target Achieved</th>
                                                                                                                  
                                                            <th>0-30 Days OS</th>
                                                            <th>30-60 Days OS</th>
                                                            <th>Above 60 Days OS</th>
                                                            <th>Total OS Amt</th>
                                                        {/*<th>Difference</th>  */}                                                      
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {
                                                            empList.length > 0 ? (
                                                                empList?.map((emp, index) => (
                                                                    <React.Fragment key={index}>
                                                                        <tr key={uuidv4()}>
                                                                        {/*<th>{emp.EmpNo}</th>*/}
                                                                        {/*<td>{emp.EmpName}</td>*/}
                                                                        {/*<td>{emp.Department}</td>*/}
                                                                        <td>{emp.Year}</td>
                                                                            <td>{emp.Label}</td>
                                                                            <td>{emp.TotalTarget?.toFixed(2)}</td>
                                                                            <td>{emp.TotalTargetAcheived?.toFixed(2)}</td>
                                                                            <td>{(emp.TotalTargetAcheived - emp.TotalTarget)?.toFixed(2)}</td>
                                                                        <td>{emp.SparesTarget?.toFixed(2)}</td>
                                                                        <td className="align_middle">
                                                                            {emp.SparesAmount?.toFixed(2)}
                                                                        </td>
                                                                        <td>{emp.ServicesTarget?.toFixed(2)}</td>
                                                                        <td className="align_middle">
                                                                            {emp.ServicesAmount?.toFixed(2)}
                                                                        </td>
                                                                        <td>{emp.AmcTarget?.toFixed(2)}</td>
                                                                        <td className="align_middle">
                                                                            {emp.AmcAmount?.toFixed(2)}
                                                                            </td>
                                                                           
                                                                            <td>{emp.outstandingDetails.OutstandingOneMonth}</td>
                                                                            <td>{emp.outstandingDetails.Outstanding2Months}</td>
                                                                            <td>{emp.outstandingDetails.OutstandingAbove3Months}</td>
                                                                            <td>{emp.outstandingDetails.OutstandingAmt}</td>
                                                                        <td>
                                                                            <button type="button"
                                                                                className="btn btn-info btn-sm margin-r-10"
                                                                                onClick={() => handleViewClick(index)}
                                                                            > View</button>
                                                                        </td>
                                                                        </tr>
                                                                        {selectedRow === index && (
                                                                            <tr>
                                                                                <td colSpan="8">
                                                                                    <InnerTable data={emp.InvoiceDetails} />
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                    </React.Fragment>

                                                              
                                                            ) )
                                                        )
                                                            :
                                                            (
                                                                <tr>
                                                                    <td colSpan="5" className='text-center'>No records found</td>
                                                                </tr>
                                                            )}
                                                </tbody>

                                            </table>
                                        </div>
                                    </div>)
                                }

                            </div>
                        </div>
                    </div>

                   
                    {/* Pagination Section */}
                    {/*{*/}
                    {/*    myBookingsList.count > recordsPerPage && <div className="pagination_section gap-2">*/}
                    {/*        <div className="pagination_section_item pagination_info">*/}
                    {/*           <a class="btn btn_info_light">Showing {currentPage + 1} of {Math.round((myBookingsList.count / recordsPerPage))} pages</a> */}
                    {/*        </div>*/}
                    {/*        <div className="pagination_section_item pagination_numbers">*/}
                    {/*            <ul>*/}
                    {/*                <li>*/}
                    {/*                    <a*/}
                    {/*                        title="First page"*/}
                    {/*                        className={currentPage === 0 ? 'disabled_button' : ''}*/}
                    {/*                        onClick={() => fetchBooking(0)}*/}
                    {/*                    >*/}
                    {/*                        <i className="fa fa-angle-double-left"></i>*/}
                    {/*                    </a>*/}
                    {/*                </li>*/}
                    {/*                <li>*/}
                    {/*                    <a*/}
                    {/*                        className={currentPage === 0 ? 'disabled_button' : ''}*/}
                    {/*                        title="Previous page"*/}
                    {/*                        onClick={() => fetchBooking(currentPage - 1)}*/}
                    {/*                    >*/}
                    {/*                        <i className="fa fa-angle-left"></i>*/}
                    {/*                    </a>*/}
                    {/*                </li>*/}
                    {/*                <li className="mr0">*/}
                    {/*                    <a title="Current page number" className="pageNumber pagination_active">{currentPage + 1}</a>*/}
                    {/*                </li>*/}
                    {/*                <li className="ml0">*/}
                    {/*                    <a title="Total number of pages" className="total_page_number pagination_active">{Math.round((myBookingsList.count / recordsPerPage))}</a>*/}
                    {/*                </li>*/}
                    {/*                <li>*/}
                    {/*                    <a*/}
                    {/*                        className={currentPage === Math.round(myBookingsList.count / recordsPerPage) - 1 ? 'disabled_button' : ''}*/}
                    {/*                        title="Next page"*/}
                    {/*                        onClick={() => fetchBooking(currentPage + 1)}*/}
                    {/*                    >*/}
                    {/*                        <i className="fa fa-angle-right"></i>*/}
                    {/*                    </a>*/}
                    {/*                </li>*/}
                    {/*                <li>*/}
                    {/*                    <a*/}
                    {/*                        title="Last page"*/}
                    {/*                        className={currentPage === Math.round(myBookingsList.count / recordsPerPage) - 1 ? 'disabled_button' : ''}*/}
                    {/*                        onClick={() => fetchBooking(Math.round((myBookingsList.count / recordsPerPage)) - 1)}*/}
                    {/*                    >*/}
                    {/*                        <i className="fa fa-angle-double-right"></i>*/}
                    {/*                    </a>*/}
                    {/*                </li>*/}
                    {/*            </ul>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*}*/}


                </div>

            </div>

        </>
    )
}

export default EmployeeMonthlyTarget;